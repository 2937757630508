<template>
<div>
    
    <div class="col-md-12" v-if="viewno==1 || viewno==2">
        <div class="row" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                <i class="fa fa fa-user animated bounceInDown show-info"></i>
                {{setheadding()}}
                </h3>
            </div>
            <div class="col-md-6  flex-row content-end" >
                <div class="text-right" style="margin-right:25px" v-if="edititem!=null">   
                    <button @click="back()" class="btn btn-primary" ><i class="fa fa-backward"></i>  Back</button>
                </div>
                <div class="text-right" v-if="viewno==1" style="margin-right:25px">   
                    <button @click="refresh()" class="btn btn-refresh" ><i class="fa fa-refresh"></i></button>
                </div>
            </div>
        </div>
    </div>
    <template v-if="viewno==1">
    <div class="panel panel-cascade" v-if="searchdetails">
        <div class="panel-body" >
            <div class="form-horizontal cascde-forms">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4 flex-between-row">
                            <label><strong>Search</strong></label>
                            <input type="text" class="form-cascade-control input-box-short" v-model="searchstring">
                        </div>
                        <div class="col-md-6 flex-center-row align-items-center">
                            <button @click="refresh()" class="btn bg-success text-white username btnsearch" type="button"><i class='bx bx-search-alt'></i> Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 mt-20">
        <div class="row">
            <div class="col-md-2">
                <strong>Show Rec.</strong>
            </div>
            <div class="col-md-2">
                <select class="form-control col-mb-1" v-model="noofrec" id="rec" @change="refresh()">
                    <option value=10>10</option>
                    <option value=25>25</option>
                    <option value=50>50</option>
                    <option value=100>100</option>
                </select>
            </div>
        </div>
    </div>
    <div class="panel panel-cascade" v-if="searchdetails">
        <div class="panel-body" >
            <div class="form-horizontal cascde-forms">
                <div class="col-md-12 flex-between-row">
                    <div class="flex-between-row flex-gap-10">
                        <label><strong>Total Records</strong></label>
                        <span class="badge bg-seagreen">
                            {{ totalsalecount }}
                        </span>
                    </div>
                    <!-- <div class="flex-between-row">
                        <label><strong>Search</strong></label>
                        <input type="text" class="form-cascade-control input-box-short" v-model="searchstring">
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 table-responsive" style="padding:0px;">
        <div class="panel">
            <div class="panel-body" style="overflow-x:auto;padding-left:0px">
                <table class="table table-bordered table-hover table-striped display">
                    <thead class="table-font">
                        <tr>
                            <th>#</th>
                            <th>Lan</th>
                            <th>Sales No.</th>
                            <th>Customer Id</th>
                            <th>Name</th>
                            <th>Down Pay</th>
                            <th>Delivered By</th>
                            <th>Plan</th>
                            <th>Entry Date</th>
                            <th>Sale Date</th>
                            <th>Entry By</th>
                            <th>Status</th>
                            <th>Print</th>
                            <th>Active Sale</th>
                        </tr>
                    </thead>
                    <closed-sales-row v-for="(item,index) in transactiondetails" v-bind:key="item.id" :item="item" :index="index"></closed-sales-row>
                </table>
            </div>
        </div>
        
    </div>
    <div class="col-md-12" style="margin-top: 20px">
        <nav aria-label="Page navigation example" style="text-align: right !important">   
            <paginate
                style="margin: 0px !important; float: right"
                :page-count="noofpages"
                :click-handler="clickCallback"
                :prev-text="'&laquo;'"
                :next-text="'&raquo;'"
                :container-class="'pagination'">
            </paginate>
        </nav>
    </div>
    </template>

    <sales-details v-if="viewno==2"></sales-details>
    <invoice v-if="viewno==4"></invoice>
    <modal name="activesaleaccount" :width="500" :height="250"  draggable=".window-header">
        <div class="col-md-12 pb-10 text-center">
            <h3 for="remark" class="window-header" style="border-bottom: 1px solid green;">Active Sale</h3>
        </div>
        <div class="col-md-12">
            <label for="remark">Comment</label>
        </div>
        <div class="col-md-12">
            <textarea id="remark" class="form-control " v-model="comments"></textarea>
        </div>
        <div class="col-md-12">
            <p> *Active Sale will Show in Sales Manager: {{setaccounttype()}}</p>
        </div>
        <div class="col-md-12 pt-20 pb-20 flex-between-row">
           <button v-if="clickadvice==false" class="btn bg-success text-white btn-sm chkcondition" @click="submitstatus()">Save</button>
            <span v-if="clickadvice" class="spinner"></span>
            <button class="btn bg-danger text-white btn-sm chkcondition" @click="close()">Close</button>
        </div>
        
    </modal>
</div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import Paginate from "vuejs-paginate";
import Constants from '../../components/utilities/Constants.vue'
import SalesDetails from '../salesmanager/SalesDetails.vue'
import Invoice from '../invoice/Invoice.vue'
import ClosedSalesRow from './ClosedSalesRow.vue';
export default {
    mixins:[Constants],
    components: { SalesDetails, Invoice, Paginate, ClosedSalesRow },
    data(){
        return {
            moment:moment,
            transactiondetails:[],
            fromdate:new Date().toISOString().slice(0,10),
            todate:new Date().toISOString().slice(0,10),
            details:[],
            searchstring:'',
            searchdetails:true,
            currentpage:1,
            noofrec:10,
            totalsalecount:0,
            comments:"",
            clickadvice:false
        }
    },
        computed:{
        ...mapGetters([
            'viewno','loggedinuser','edititem',"accountstatus","loanaccounttype"
        ]),
        noofpages(){
            return Math.ceil(parseInt(this.totalsalecount) / parseInt(this.noofrec))
        },
    },
    watch:{
        '$route.params.status'() {
            this.refresh()
        }
    },
    mounted(){
        this.refresh();
    },
    methods:{
        setheadding(){
            if(this.$route.params.status==1){
                return "Clear Account"
            }else if(this.$route.params.status==2){
                return "Defaulter Account"
            }else if(this.$route.params.status==3){
                return "Legal Account"
            }else if(this.$route.params.status==4){
                return "Concesation Account"
            }
        },
        submitstatus(){
            if(this.accountstatus!=null){
                let param={comments:this.comments,loanaccountid:this.accountstatus.loandetails.id,
                    accountstatus:this.loanaccounttype}
                window.axios.post('api/account/closedsale/update',param)
                    .then((response) => this.processcommentResponse(response.data))
                    .catch((err) => {
                   console.log('', err)
                })
            }
            
        },
        processcommentResponse(){
            this.$notify({text:'Account Activated Successfully',type:'Success'})
            this.$modal.hide('activesaleaccount');
            this.comments=''
            this.refresh();
        },
        close(){
            this.$modal.hide('activesaleaccount')
            this.comments=""
        },
        setaccounttype(){
            if(this.loanaccounttype==5){
                return "Closed Accounts"
            }else if(this.loanaccounttype==0){
                return "Active Accounts"
            }
        },
        clickCallback (currentpage) {
            this.currentpage=currentpage
            this.$store.commit("assigncurrentpage",currentpage);
            this.refresh();
        },
        refresh(){
            console.log("total sale",this.totalsalecount)
            let orders=[];
            // this.searchdetails=true
            orders.push("usersequence")
            this.$store.commit("assignloadingstatus",1)
            let param = {searchstring:this.searchstring,noofrec:this.noofrec??100,currentpage:this.currentpage??1,
                accountstatus:this.$route.params.status}
            window.axios.post('api/account/closedsale/feth',param)
                .then(response=>this.processResponse(response.data))
                .catch(error=>console.log(error));
            let param1={searchstring:this.searchstring,accountstatus:this.$route.params.status}
            window.axios.post('api/account/closedsale/count',param1)
                .then(response=>this.processResponseCount(response.data))
                .catch(error=>console.log(error));
            // this.$store.dispatch("fetchemployees")
        },
        processResponseCount(data){
            this.totalsalecount=data
        },
        processResponse(data){
            this.$store.commit("assignloadingstatus",0)
            this.transactiondetails = data;
        },
        processUpdateResponse(data){
            alert("success",data)
                this.$store.commit("assignloadingstatus",0)
        },
        transactiondetailsmodal(item){
            let param = {transactionid:item.id};
            window.axios.post('api/stransaction/details',param)
                .then(response=>this.processDetailResponse(response.data))
                .catch(error=>console.log(error));
                this.$store.commit("assignloadingstatus",1)
        },
        processDetailResponse(data){
            this.details=data.transactiondetails
            this.id=data.transaction.id
                this.$store.commit("assignloadingstatus",0)
        },
        back(){
            this.$store.commit('assignviewno',1)
            this.$children.localviewno=1
            this.refresh();
            this.$store.commit('assigntransaction',null)
            this.$store.commit('assignedititem',null)
            this.$store.commit('assigncartitems',[])
        },
        
    }
}
</script>