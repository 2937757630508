<template>
    <tbody class="table-font">
        <tr >
            <td>{{index+1}}</td>
            <td><a v-if="item.loandetails!=null" href="#" @click="loandetails(item.loandetails.id)">
                    <div >
                        <span style="font-size:11px"> {{item.loandetails.manualid}}</span>
                    </div>
                </a>
            </td>
            <td>{{item.usersequence}}</td>
            <td><div v-if="item.user!=null && item.customername==null">{{item.user.generatedid}}</div>
            <div v-else>{{item.customername}}</div></td>
            <td>{{ item.user==null?'':item.user.name }}</td>
            <td><div v-if="item.loandetails!=null">{{item.loandetails.downpayment}}</div></td>
            <td><span v-if="item.saleby!=null">{{item.saleby.name}}</span></td>
            <td v-if="item.loandetails!=null">EMI</td>
            <td v-else>Cash</td>
            <td>{{moment(item.created_at).format('DD-MM-YYYY')}}</td>
            <td><div v-if="item.items!=null">{{moment(item.items.created_at).format('DD-MM-YYYY')}}</div></td>
            <td><div v-if="item.enteredby!=null">{{item.enteredby.name}}</div></td>
            <td><span v-if="item.loandetails!=null">{{setaccounttype(item)}}</span></td>
            <td>
                <span>
                    <button class="btn btn-primary-print" @click="printtransaction(item)"><i class="fa fa-print" style="font-size:12px"></i></button>
                </span>
            </td>
            <td>
                 <div class="form-group">
                <select class="form-control" v-model="item.loandetails.accounttype" @change="accountchange(item)">
                    <option :value="0">Due Acc.</option>
                    <option :value="1">Clear Acc</option>
                    <option :value="2">Deafulter Acc</option>
                    <option :value="3">Legal Acc</option>
                    <option :value="4">Concession Acc</option>
                </select>
            </div>
            </td>
        </tr>
    </tbody>
</template>
<script>
import moment from "moment";
// import { mapGetters } from "vuex";
export default {
    props:["item","index"],
    data() {
        return {
            accounttype:'',
            moment:moment
        }
    },
    methods: {
        setaccounttype(item){
            if(item.loandetails.accountstatus==0){
                return "Active"
            }else if(item.loandetails.accountstatus==1){
                return "Clear Sale"
            }else if(item.loandetails.accountstatus==2){
                return "Defaulter Sale"
            }else if(item.loandetails.accountstatus==3){
                return "Legal Sale"
            }else if(item.loandetails.accountstatus==4){
                return "Concesation Account"
            }else if(item.loandetails.accountstatus==5){
                return "Closed Sale"
            }
        },
        accountchange(item){
            console.log(item.loandetails.accounttype)
            this.$store.commit("assignaccountstatus",item)
            this.$store.commit("assignloanaccounttype",item.loandetails.accounttype)
            this.$modal.show('activesaleaccount');
        },
        printtransaction(item){
            let param = {transactionid:item.id};
            window.axios.post('api/stransaction/details',param)
                .then(response=>this.processResponseData(response.data))
                .catch(error=>console.log(error));
        },
        processResponseData(data){
            //  console.log("data", data)
             this.$store.commit("assignloadingstatus",0)
            this.$store.commit("assigntransactiondetails",data.transactiondetails);
            this.$store.commit('assigntransaction',data.transaction);
            //this.$store.commit('assignbarcodeforproduct',data.barcode);
            //this.$router.push({name:"invoice"})
            this.$store.commit('assignviewno',4);
        },
        transactionedit(item){
            // console.log("item",item)
            this.$store.commit('assigntransaction',item)
            this.$store.commit('assignedititem',item)
            this.saletransactionedit(item.id)
            this.$store.commit('assignviewno',3)
        },
        
        loandetails(id){
           let param = {id:id}
            this.$http.post('api/loan/details',param)
            .then((response) => this.processloanResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processloanResponse(data){
             this.$store.commit("assignloadingstatus",0)
            this.$store.commit('assignedititem',data)
            this.$store.commit('assignviewno',2)
            this.$children.localviewno=1
        },
        
    },
}
</script>